<template>
  <div class="emailtemplates">
    <header>
      <div class="row">
        <div class="col-sm-6">
          <h1>Email Templates</h1>
        </div>
        <div class="col-sm-6 text-right">
          <router-link class="btn btn-lg btn-primary" to="/emailtemplate/new">
            <i class="fas fa-plus-square"></i>
            New
          </router-link>
        </div>
      </div>
    </header>
    <div>
      <div class="row">
        <div class="col-5">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-2">
                <label class="h5">Report Type</label>
              </div>
              <div class="col-10">
                <multiselect-text
                  v-model="reporttypeoptionsData"
                  :options="jobtypelist"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                  @tag="addReportType"
                >
                </multiselect-text>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Default</label>
            <div
              class="col-sm-9"
              style="
                padding-top: calc(0.375rem + 1px) !important;
                padding-bottom: calc(0.375rem + 1px) !important;
              "
            >
              <DxSwitch
                :value="defaultdata"
                @value-changed="
                  defaultdata = $event.value;
                  filterData();
                "
              />
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-2">
                <label class="h5">Target</label>
              </div>
              <div class="col-10">
                <multiselect-text
                  v-model="targetoptionsData"
                  :options="targetList"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                  @select="filterData"
                >
                </multiselect-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="col-12">
            <div class="form-group row">
              <div class="col-2">
                <label class="h5">Chron Order</label>
              </div>
              <div class="col-10">
                <multiselect-text
                  v-model="chroneoptionsData"
                  :options="chronorderList"
                  multiple
                  :close-on-select="false"
                  :allowEmpty="true"
                  taggable
                  @select="filterData"
                >
                </multiselect-text>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7">
          <button class="btn btn-primary" @click.prevent="clearfilter()">
            <i class="fas fa-ban"></i> Clear
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!emailtemplateslist.length && !hasBlockingRequests"
      class="alert alert-warning text-center"
    >
      <h2 class="mt-1 mb-2">No email templates found</h2>
    </div>

    <div v-else>
      <table class="table table-hover" style="padding-bottom: 60px">
        <thead>
          <th width="25%">Title</th>
          <th width="8%">Default</th>
          <th width="30%">Report Type</th>
          <th width="15%">Target</th>
          <th width="15%">Chron Order</th>
          <th width="7%"><span class="sr-only">Actions</span></th>
        </thead>
        <tbody>
          <tr
            v-for="emailtemplate in emailtemplateslist"
            :key="emailtemplate.id"
            @click="edit(emailtemplate)"
          >
            <td class="align-middle">{{ emailtemplate.title }}</td>
            <td class="align-middle">
              <span class="multiselecttags">
                <span
                  v-if="emailtemplate.defaulttemplate"
                  class="multiselecttag"
                  >Default</span
                >
              </span>
            </td>
            <td class="align-middle">
              <span class="multiselecttags">
                <span
                  class="multiselecttag"
                  v-for="reporttype in emailtemplate.reporttypes"
                  :key="reporttype"
                  >{{ reporttype }}</span
                >
              </span>
            </td>
            <td class="align-middle">
              <span class="multiselecttags">
                <span
                  class="multiselecttag"
                  v-for="t in emailtemplate.target"
                  :key="t"
                  >{{ t }}</span
                >
              </span>
            </td>
            <td class="align-middle">
              <span class="multiselecttags">
                <span
                  class="multiselecttag"
                  v-for="c in emailtemplate.chronorder"
                  :key="c"
                  >{{ c }}</span
                >
              </span>
            </td>
            <td class="align-middle text-right">
              <ul class="list-unstyled mb-0">
                <li class="list-item" @click.stop>
                  <button
                    class="btn btn-outline-danger"
                    @click="remove(emailtemplate)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { EmailTemplate } from "@/models";
import { DxSwitch } from "devextreme-vue/switch";

const store = useStore();
const router = useRouter();
const toasted = useToast();
const chroneoptions = ref([]);
const reporttypeoptions = ref([]);
const emailtemplateslist = ref([]);
const targetoptions = ref([]);
const defaultdata = ref(undefined);
const actProperty: any = inject("actProperty");

const hasBlockingRequests = computed(
  () => store.getters["app/hasBlockingRequests"] as boolean
);
const emailtemplates = computed(
  () => store.getters["emailtemplates/list"] as EmailTemplate[]
);
const jobtypelist = computed(() => store.getters["diary/jobtypelist"]);
const getEmailTemplates = async () => {
  await store.dispatch("emailtemplates/getEmailTemplates");
  emailtemplateslist.value = emailtemplates.value;
};
const reporttypeoptionsData = computed({
  get: () => reporttypeoptions.value,
  set: (val: string[]) => addReportType(val),
});
const targetoptionsData = computed({
  get: () => targetoptions.value,
  set: (val: string[]) => addTargetData(val),
});
const chroneoptionsData = computed({
  get: () => chroneoptions.value,
  set: (val: string[]) => addChrone(val),
});
const deleteEmailTemplate = (emailtemplate: EmailTemplate) => {
  store.dispatch("emailtemplates/deleteEmailTemplate", emailtemplate);
};

const targetList: string[] = [
  "Booked By",
  "Data Entry",
  "Managed Client",
  "Non-Managed Client",
  "Tenant",
  "Landlord",
  "Invoice",
];
const chronorderList: string[] = [
  "Initial confirmation",
  "First Chaser",
  "Second Chaser",
  "Third Chaser",
  "Fourth Chaser",
  "Fifth Chaser",
  "Sixth Chaser",
  "Last Minute",
  "Reminder",
  "Change Of Date",
  "Restore Booking",
  "Confirmed",
  "Not Confirmed",
  "Delete Booking",
  "Correspondance/Feedback to Client",
  "Issue Report",
];

onMounted(async () => {
  getEmailTemplates();
});
const edit = (emailtemplate: EmailTemplate) => {
  if (emailtemplate.hasOwnProperty("id")) {
    router.push({
      name: "emailtemplate",
      params: { id: emailtemplate.id },
    });
  } else {
    actProperty.displayError("Error");
  }
};

const remove = async (emailtemplate: EmailTemplate) => {
  actProperty
    .confirmPrompt()
    .then(() => deleteEmailTemplate(emailtemplate))
    .then(() => toasted.success("EmailTemplate deleted"))
    .catch((err: any) => actProperty.displayError(err));
};
const addReportType = async (data: string[]) => {
  reporttypeoptions.value = data;
  filterData();
};
const addTargetData = async (data: string[]) => {
  targetoptions.value = data;
  filterData();
};
const addChrone = async (data: string[]) => {
  chroneoptions.value = data;
  filterData();
};
const clearfilter = () => {
  reporttypeoptions.value = [];
  targetoptions.value = [];
  chroneoptions.value = [];
  defaultdata.value = undefined;
  emailtemplateslist.value = emailtemplates.value;
};
const filterData = () => {
  emailtemplateslist.value = emailtemplates.value.filter((f: EmailTemplate) => {
    // Check if chroneoptions.value has any selected options
    const chronFilterActive = chroneoptions.value.length > 0;

    // Check if targetoptions.value has any selected options
    const targetFilterActive = targetoptions.value.length > 0;

    // Check if reporttypeoptions.value has any selected options
    const reporttypeoptionsActive = reporttypeoptionsData.value.length > 0;

    // Check if defaultdata.value is not undefined or empty
    const defaultFilterActive =
      defaultdata.value !== "" && defaultdata.value !== undefined;

    // Apply all filters with logical AND, skipping inactive filters
    return (
      (!defaultFilterActive || f.defaulttemplate === defaultdata.value) &&
      (!chronFilterActive ||
        f.chronorder.some((chron) => chroneoptions.value.includes(chron))) &&
      (!targetFilterActive ||
        f.target.some((target) => targetoptions.value.includes(target))) &&
      (!reporttypeoptionsActive ||
        f.reporttypes.some((report) =>
          reporttypeoptionsData.value.includes(report)
        ))
    );
  });
};
</script>

<style scoped lang="scss">
.multiselecttag {
  position: relative;
  display: inline-block;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}

.multiselecttags {
  display: block;
  font-size: 14px;
}
</style>
